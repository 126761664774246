<template lang="html">
  <div class="container-xxl pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Chats' }">Чаты</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ $store.state.chat.name || $store.state.chat.telegram_name }}
        </li>
      </ol>
    </nav>
    <div class="d-flex">
      <h3 class="mb-3">
        Чат {{ $store.state.chat.name || $store.state.chat.origin_name }}
        <span class="btn btn-info btn-sm ms-3">
          <strong>{{ $store.state.chat.scoring }}</strong>
        </span>
      </h3>
      <div v-if="$store.state.chatTab === 'chat'" class="ms-auto d-flex align-items-center">
        <div class="form-check form-switch mb-0 me-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            v-model="$store.state.chat.not_parse"
            @change="$socket.emit('chats', $store.state.chat)"
            >
          <label class="form-check-label" for="flexSwitchCheckChecked">Остановить парсинг</label>
        </div>
        <div class="">
          <button
            v-if="$store.state.chat.origin === 'tg_app'"
            class="btn btn-secondary btn-sm"
            @click="$store.commit('mergeStore', {loading: true}); $socket.emit('get_chat_history', $store.state.chat)">
            Скачать историю сообщений
          </button>
          <button
            v-else
            class="btn btn-secondary btn-sm"
            @click="getChatHistory">
            Скачать историю сообщений
          </button>
        </div>
      </div>
    </div>
    <p v-if="$store.state.chat.desc">{{ $store.state.chat.desc }}</p>

    <ul class="nav nav-pills mb-4">
      <li
        v-for="t in tabs"
        :key="t.key"
        class="nav-item">
        <button
          class="nav-link"
          :class="{active: t.key === $store.state.chatTab}"
          @click="$store.commit('mergeStore', {chatTab: t.key})">
          {{ t.name }}
        </button>
      </li>
    </ul>

    <div :class="{'d-none': $store.state.chatTab != 'chat'}" id="chat-messages">
      <ChatMessages />
    </div>

    <div v-if="$store.state.chat.id">

      <div :class="{'d-none': $store.state.chatTab != 'statistic'}">
        <Statistic />
      </div>

      <div :class="{'d-none': $store.state.chatTab != 'settings'}">
        <div class="row align-items-center justify-content-center">

          <div class="col-auto mb-4">
            <div class="card card-body">
              <h5 class="card-title mb-3">Форма</h5>
              <div  class="mb-3">
                <label class="form-label">Название</label>
                <input
                  type="text"
                  v-model="$store.state.chat.name"
                  class="form-control"
                  placeholder="Название чата">
              </div>
              <div  class="mb-3">
                <label class="form-label">Описание</label>
                <textarea
                  v-model="$store.state.chat.desc"
                  class="form-control"
                  maxlength="100"
                  rows="3"
                  ref="textarea"
                  @focus="resize_textarea"
                  @keyup="resize_textarea"
                  placeholder="Описание">
                </textarea>
              </div>
              <div  class="mb-3">
                <label class="form-label">Ссылка публичного чата в телеграме</label>
                <input
                  type="text"
                  readonly
                  v-model="$store.state.chat.url"
                  class="form-control"
                  placeholder="https://t.me/MID_Russia">
              </div>
              <div  class="mb-3">
                <label class="form-label">Имя в телеграме</label>
                <input
                  type="text"
                  readonly
                  v-model="$store.state.chat.telegram_name"
                  class="form-control"
                  placeholder="MID_Russia">
              </div>
              <div  class="mb-3">
                <label class="form-label">Ссылка приватного чата в телеграме</label>
                <input
                  type="text"
                  readonly
                  v-model="$store.state.chat.link"
                  class="form-control"
                  placeholder="https://t.me/+N1md43feior89">
              </div>
              <div  class="mb-3">
                <label class="form-label">Telegram id</label>
                <input
                  type="text"
                  readonly
                  v-model="$store.state.chat.telegram_id"
                  class="form-control">
              </div>
              <div  class="mb-3">
                <label class="form-label">Аккаунт</label>
                {{ $store.state.chat.account.first_name }}
                <!-- <select
                  class="form-select"
                  v-model="$store.state.chat.account">
                  <option
                    v-for="account in $store.state.bots"
                    :key="account.id"
                    :value="account.id">
                    {{ account.first_name }} {{ account.last_name }} {{ account.username }} {{ account.phone }}
                  </option>
                </select> -->
              </div>

              <button
                class="btn btn-primary me-3"
                @click="$socket.emit('chats', $store.state.chat)">
                Сохранить
              </button>
            </div>
          </div>

          <div class="col-auto mb-4">
            <div class="card card-body pb-0">
              <h5 class="card-title mb-3">Теги</h5>
              <ChatTags />
            </div>
          </div>

          <div class="col-auto mb-4">
            <div class="card card-body pb-0">
              <h5 class="card-title">Лейблы</h5>
              <div class="d-flex flex-wrap">
                <LabelItem
                  v-for="label in $store.state.chat.labels"
                  :key="label.id"
                  :label="label"
                  class="bg-pink"/>
              </div>
              <div class="d-flex flex-wrap">
                <LabelItem
                  v-for="label in $store.state.labels.filter(c => !$store.state.chat.label_ids.includes(c.id))"
                  :key="label.id"
                  :label="label"
                  class="bg-light"/>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
moment.locale('ru')

import ChatTags from '../components/ChatTags'
import LabelItem from '../components/LabelItem'
import Statistic from '../components/Statistic'
import ChatMessages from '../components/chat/Messages'

export default {
  name: 'Chat',
  created: function () {
    this.moment = moment
  },
  data () {
    return {
      id: null,
      // tab: 'statistic',
      tabs: [
        {
          name: 'Статистика',
          key: 'statistic',
        },
        {
          name: 'Сообщения',
          key: 'chat',
        },
        {
          name: 'Настройки',
          key: 'settings',
        },
      ],
    }
  },
  components: {
    ChatTags,
    LabelItem,
    Statistic,
    ChatMessages,
  },
  methods: {
    async getChatHistory () {
      console.log('getChatHistory', this.$store.state.chat.account_id)
      try {
        this.$toast.success('Запрос на получение истории сообщений отправлен')
        const response = await axios.post(`/e/manage`, {
          id: this.$store.state.chat.account.id,
          phone: this.$store.state.chat.account.phone,
          action: 'dialog',
          origin: this.$store.state.chat.origin,
          origin_id: this.$store.state.chat.origin_id,
          origin_name: this.$store.state.chat.origin_name,
          last_message_id: this.$store.state.chat.last_message_id,
          last_message_date: this.$store.state.chat.last_message_date,
        })
        if (response.status === 200) {
          if (response.data.status === 'error') {
            this.$toast.error(response.data.message)
          } else if (response.data.id) {
            this.$toast.success('Начался парсинг диалога')
          } else {
            this.$toast.error('Отсутствует авторизованная сессия')
          }
        }
      } catch (e) {
        this.$toast.error(e.message)
        console.error(e)
      }
    },
    getChat () {
      this.$socket.emit('get_chat', {id: this.id})
    },

    resize_textarea () {
      const { textarea } = this.$refs
      textarea.style.height = 0
      textarea.style.height = (textarea.scrollHeight) + 'px'
      // console.log('textarea.scrollHeight', textarea.scrollHeight)
      // textarea.style.height = textarea.scrollHeight - 4 + 'px'
    }
  },
  async mounted () {
    try {
      this.id = this.$route.params.id
      const tab = this.$route.query.tab || 'statistic'
      this.$store.commit('mergeStore', {messages: [], chat: {}, chatTab: tab})
      this.getChat()
      this.$socket.emit('tags_list')
      this.$socket.emit('get_data', ['bots', 'labels'])
      // this.$store.commit('setData', {attr: 'category', value: this.$store.state.categories[0]})
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss">
.textarea{
  overflow-y: hidden;
}
</style>
