<template>
  <div class="mb-3">
    <div class="tag d-flex align-items-center flex-wrap">
      <button
        class="btn me-3"
        :class="{
          'btn-primary': tag.chat_ids.includes($store.state.chat.id),
          'btn-secondary': !tag.chat_ids.includes($store.state.chat.id),
        }"
        @click="selectTag(tag)">
        {{ tag.name }}
      </button>
    </div>
    <div v-if="tag.tags.length" class="ps-3 pt-3">
      <ChatTag :tag="child" v-for="child in tag.tags" :key="child.id" />
    </div>

  </div>
</template>

<script>
import ChatTag from './ChatTag'
export default {
  name: 'ChatTag',
  components: {
    ChatTag
  },
  props: {
    tag: Object
  },
  methods: {
    selectTag (tag) {
      if (tag.chat_ids.includes(this.$store.state.chat.id)) {
        this.$toast.info('Отключение тега')
      } else {
        this.$toast.info('Включение тега')
      }
      this.$socket.emit('tag_chat', {
        tag_id: tag.id,
        chat_id: this.$store.state.chat.id
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
