<template>
  <div class="">
    <div class="card card-body mb-3">
      <div class="row">
        <div class="col-auto" v-if="$store.state.chatSelectedMessages.length">
          <button class="btn btn-dark" @click="$socket.emit('send_messages', $store.state.chatSelectedMessages); $store.commit('mergeStore', {chatSelectedMessages: []})">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
            </svg>
          </button>
        </div>
        <div class="col-auto pt-2">
          <div class="form-check form-switch">
            <input
              v-model="$store.state.messagesSearch.labels"
              @change="getChatMessages"
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked">
            <label class="form-check-label" for="flexSwitchCheckChecked">Лейблы</label>
          </div>
        </div>
        <div class="col-auto">
          <label for="inputSearch" class="visually-hidden">Поиск</label>
          <input
            v-model="$store.state.messagesSearch.text"
            @change="getChatMessages"
            type="text"
            class="form-control"
            id="inputSearch"
            placeholder="Поиск по чату">
        </div>
        <div class="col-auto">
          <label for="inputDateFrom" class="visually-hidden">Дата от</label>
          <input
            v-model="$store.state.messagesSearch.dateFrom"
            @change="getChatMessages"
            type="datetime-local"
            class="form-control"
            id="inputDateFrom"
            placeholder="Дата от">
        </div>
        <div class="col-auto">
          <label for="inputDateTo" class="visually-hidden">Дата до</label>
          <input
            v-model="$store.state.messagesSearch.dateTo"
            @change="getChatMessages"
            type="datetime-local"
            class="form-control"
            id="inputDateTo"
            placeholder="Дата до">
        </div>
        <div class="col-2">
          <label for="inputDateTo" class="visually-hidden">Аккаунты</label>
          <vSelect
            v-model="$store.state.messagesSearch.accountIds"
            :options="$store.state.chatAccounts"
            @search="fetchOptions"
            :getOptionLabel="getSelectLabel"
            placeholder="Аккаунты"
            :multiple="true"
            @option:selected="getChatMessages"
            @option:deselected="getChatMessages"
          />
          <!-- :taggable="true"
          :pushTags="true"
           -->
        </div>
      </div>
    </div>

    <MessagesTable
      :messages="$store.state.messages"
      :highlightLabels="$store.state.messagesSearch.labels"/>

    <pagination
      v-model="page"
      :records="$store.state.messagesCount"
      :per-page="100"
      :options="{
        theme: 'bootstrap3',
        texts: {
          count: 'Показано с {from} до {to} из {count} сообщений|{count} сообщений|Одно сообщение',
          first: 'Первая',
          last: 'Последняя',
        }
      }"
      @paginate="getChatMessages"
    />
  </div>
</template>

<script>
import Pagination from 'v-pagination-3'
import vSelect from 'vue-select'
import MessagesTable from './MessagesTable'
import moment from 'moment'
moment.locale('ru')
export default {
  components: {
    Pagination,
    vSelect,
    MessagesTable,
  },
  created: function () {
    this.moment = moment
  },
  data () {
    return {
      id: null,
      page: 1,
      selected: [],
      dateFrom: null,
      dateTo: null,
      // search: {
      //   labels: false,
      //   text: null,
      //   dateFrom: null,
      //   dateTo: null,
      //   accountIds: [],
      // }
    }
  },
  methods: {
    fetchOptions (search, loading) {
      if (search.length === 0) {
        this.$store.commit('mergeStore', {
          chatAccounts: []
        })
      } else if (search.length > 1) {
        loading(true)
        this.getChatMembers(loading, search)
      }
    },

    async getChatMembers (loading, search) {
      try {
        const res = await fetch(`/api/chats/${this.$store.state.chat.id}/accounts?q=${search}`)
        const data = await res.json()
        loading(false)
        console.log('data.accounts', data.accounts.length);
        this.$store.commit('mergeStore', {
          chatAccounts: data.accounts
        })
        console.log('state.chatAccounts', this.$store.state.chatAccounts.length)
        console.log('state.messagesSearch.accountIds', this.$store.state.messagesSearch.accountIds);
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    getChatMessages () {
      this.$store.commit('mergeStore', {loading: true})
      this.$socket.emit('get_chat_messages', {
        id: this.id,
        page: this.page,
        search: {
          labels: this.$store.state.messagesSearch.labels,
          text: this.$store.state.messagesSearch.text,
          dateFrom: this.moment(this.$store.state.messagesSearch.dateFrom).unix(),
          dateTo: this.moment(this.$store.state.messagesSearch.dateTo).unix(),
          accountIds: this.$store.state.messagesSearch.accountIds,
        }
      })
    },
    getSelectLabel (option) {
      let label = ''
      if (option.account__username) {
        label += option.account__username + ' '
      }
      if (option.account__first_name) {
        label += option.account__first_name + ' '
      }
      if (option.account__last_name) {
        label += option.account__last_name + ' '
      }
      return label
    },

  },
  async mounted () {
    try {
      this.id = this.$route.params.id
      const dateFrom = this.$route.query.dateFrom
      const dateTo = this.$route.query.dateTo
      if (dateFrom && dateTo) {
        this.$store.commit('mergeStore', {
          messagesSearch: {
            ...this.$store.state.messagesSearch,
            dateFrom: moment(dateFrom * 1000).format().substr(0, 16),
            dateTo: moment(dateTo * 1000).format().substr(0, 16)
          }
        })
      }
      this.$store.commit('mergeStore', {
        loading: true,
        messagesCount: 0
      })
      this.getChatMessages()
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
tr{
  .btn{
    display: none;
  }
  &:hover{
    .btn{
      display: inline-block;
    }
  }
}
</style>
