<template>
  <div class="">
    <ChatTag :tag="tag" v-for="tag in tags" :key="tag.id" />
  </div>
</template>

<script>
import ChatTag from './ChatTag'
export default {
  name: 'ChatTags',
  components: {
    ChatTag
  },

  data () {
    return {
      id: null,
    }
  },

  computed: {
    tags () {
      if (this.id) {
        console.log('this.id', this.id)
      }
      return this.$store.state.tags
    }
  },

  async mounted () {
    try {
      // this.$store.commit('mergeStore', {tags: []})
      // if (this.$route.name === 'Tag') {
      //   this.id = this.$route.params.id
      //   this.$socket.emit('tags_list', {tag_id: this.id})
      // } else {
      //   this.$socket.emit('tags_list')
      // }
    } catch (e) {
      console.error(e)
      // this.$toasted.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
